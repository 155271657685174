<template>

  <div class="homeGIT_content">

    <div class="container">

      <div class="row">
          <img class="mc_log" src="images/comm/mc_logo.png" />
      </div>  


      <div class="row">
        <!-- <div class="homeGIT_info-list"> -->
          <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">Voornaam</div>
            <input class="homeGIT_info-edit" v-model="first_name">
          </div>
          <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">Achternaam</div>
            <input class="homeGIT_info-edit" v-model="last_name">
          </div>
        <!-- </div> -->
      </div>


      <div class="row">
        <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">Bedrijfsnaam</div>
            <input class="homeGIT_info-edit" v-model="company_name">
        </div>  
        <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">E-mailadres<span class="homeGIT_highlight">*</span></div>
            <input class="homeGIT_info-edit" v-model="email_address">
        </div>
      </div>



      <div class="row">
        <div class="col-md-12 homeGIT_info-cont1">
          <div class="homeGIT_info-title">Bericht</div>
          <textarea class="homeGIT_info-mledit" v-model="message"/>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <button class="homeGIT_info-btn" @click="clickCommit()">Neem contact op</button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button class="homeGIT_info-btn" @click="gotoCAREK()">ga naar CAREK</button>
        </div>
      </div>

    </div>



  </div>



</template>


<script>



export default {

  components: {

  },


  computed: {

  },

  data(){
    return {
      globalparam: [],
      answerlist_open: [false, false, false, false],
      first_name: '',
      last_name: '',
      company_name: '',
      email_address: '',
      message: '',
    };
  }, 

  computed: {
    list_group() {
      return this.sliceList(this.list);
    },
  },

  mounted(){
    // this.GetParamListByNameList();
  },

  methods: {

    async GetParamListByNameList() {
      let namelist = "GetInTouchCall,GetInTouchMail";
      let result = await this.$API.globalparam.reqGetParamListByNameList(namelist);
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
      }
    },
    GetParam(paramname){
      for(let i=0;i<this.globalparam.length;i++){
        if(this.globalparam[i].paramname == paramname)
          return this.globalparam[i].paramvalue;
      }
      return "";
    },


    async clickCommit(){
      // alert(this.first_name);
      // alert(this.last_name);
      // alert(this.company_name);
      // alert(this.email_address);
      // alert(this.message);



      let data = {firstname: this.first_name, lastname: this.last_name, companyname: this.company_name, emailaddress: this.email_address, message: this.message};
      // alert(JSON.stringify(data));

      let result = await this.$API.decoration.reqAddDecoration(data);
      // alert(JSON.stringify(result));
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          // this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
          // alert("Thank you for leaving your contact information. Relevant personnel will contact you in the future. Thank you!");
          //跳转
          this.$router.push({name: 'decorationsuccess'})
      }


    },

    async gotoCAREK(){
        //跳转
        this.$router.push({name: 'home'})


      },



  },
};
</script>

<style scoped>


.homeGIT_content{
  padding: 50px 20px 50px 20px;
}



.mc_log{
  width: 126px;
  height: 102px;
  margin-bottom: 50px;
}


.homeGIT_line {  
  height: 1px;  
  background-color: black;
  margin-bottom: 20px;
}



.homeGIT_t1{
  display: flex;
  flex-direction: column;  
  align-items: left; /* 垂直居中 */  
  justify-content: left; /* 水平居中 */
}


.homeGIT_t1_a1{
  color: orange; 
  font-size: 24px;
  text-align: left;
  margin-bottom: 30px;  
}


.homeGIT_t2{
  display: flex;
  flex-direction: column;  
  align-items: left; /* 左对齐 */  
  justify-content: center; /* 水平居中 */
  margin: 50px 0 50px 0;
}


.homeGIT_t2_t1{
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 30px;
}


.homeGIT_t2_a1{
  font-size: 18px;
  line-height: 2;
  margin-top: 20px;
}



.homeGIT_highlight {  
    color: orangered;  
    font-weight: bold;  
}  








.homeGIT_info-list{
  margin-top: 50px;
  display: flex;  
  flex-wrap: wrap;
  justify-content: space-between; 
  row-gap: 20px;
}

.homeGIT_info-cont {  
  margin-bottom: 20px;
  padding-right: 20px;
}  


.homeGIT_info-cont1 {
  margin-top: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;  
}  


.homeGIT_info-title {  
  font-weight: bold;  
  font-size: 14px;
}  
  
.homeGIT_info-edit {  
  margin-top: 10px;
  height: 35px;
  width: 100%;
  font-size: 16px;
}

.homeGIT_info-mledit {  
  margin-top: 10px;
  height: 70px;
  width: 100%;
  font-size: 16px;
  
}


.homeGIT_info-btn{
  margin-top: 20px;
  background-color: orangered;
  font-size: 16px;
  color: white;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}


</style>