<template>
 



  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">  
    <a class="navbar-brand head_main_logo"><img src="images/comm/main_logo.png" class="main_log"></a>  
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
      <span class="navbar-toggler-icon"></span>
    </button>  
    <div class="collapse navbar-collapse justify-content-end head_nav_top" id="navbarNav">  
      <ul class="navbar-nav hand_pointer">  
        <li class="[$route.meta.curtab == 0 ? 'active' : '', 'nav-item']">  
          <a class="nav-link" @click="toHome()">{{ $t('header.menu_about') }}</a>  
        </li>  
        <li class="[$route.meta.curtab == 1 ? 'active' : '', 'nav-item']">  
          <a class="nav-link" @click="toContactus()">{{ $t('header.menu_contact') }}</a>  
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-bs-toggle="dropdown">
            {{ $t('header.menu_lang') }}
          </a>
          <div class="dropdown-menu">
            <!-- <a class="dropdown-item" @click="Change2Zh()">{{ $t('header.menu_lang_zh') }}</a> -->
            <a class="dropdown-item" @click="Change2Nl()">{{ $t('header.menu_lang_nl') }}</a>
            <a class="dropdown-item" @click="Change2En()">{{ $t('header.menu_lang_en') }}</a>
          </div>
        </li> 
      </ul>  
    </div>  
  </nav>  


</template>







<script>
import 'bootstrap/dist/css/bootstrap.css';
import Cookies from 'js-cookie';


export default {


  data(){
    return {
      globalparam: [],
    };
  }, 

  mounted(){
    // this.GetParamListByNameList();
  },



  methods: {


    async GetParamListByNameList() {
      let namelist = "公司简称,公司全称";
      let result = await this.$API.globalparam.reqGetParamListByNameList(namelist);
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
      }
    },
    GetParam(paramname){
      for(let i=0;i<this.globalparam.length;i++){
        if(this.globalparam[i].paramname == paramname)
          return this.globalparam[i].paramvalue;
      }
      return "";
    },

    //首页
    toHome() {
      this.$router.push(
        { name: "home" },
        () => {},
        () => {}
      );
    },
    
    //联系我们
    toContactus() {
      this.$router.push(
        { name: "contactus" },
        () => {},
        () => {}
      );
    },


    //切换为英文
    Change2En() {
      this.$i18n.locale = "en";
      Cookies.set('locale_lang', 'en');
    },
    //切换为中文
    Change2Zh() {
      this.$i18n.locale = "zh";
      Cookies.set('locale_lang', 'zh');
    },
    //切换为荷兰语
    Change2Nl() {
      this.$i18n.locale = "nl";
      Cookies.set('locale_lang', 'nl');
    },


  },
};
</script>




<style scoped>

.head_main_logo{
  margin-left: 100px;
  display: flex;  
  align-items: center; /* 垂直居中 */  
  justify-content: center; /* 水平居中，如果需要的话 */  
}

.main_log{
  width: 219px;
  height: 57px;
}

.head_nav_top{
  margin-right: 10px;
}


</style>