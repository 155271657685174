//配置路由的地方
import Vue from 'vue';
import VueRouter from 'vue-router';

//使用插件
Vue.use(VueRouter);

//引入路由组件
import Home from '@/pages/Home'
import Contactus from '@/pages/Contactus'
import GITSuccess from '@/pages/GITSuccess'
import Decoration from '@/pages/Decoration'
import DecorationSuccess from '@/pages/DecorationSuccess'



// import { GetDownloadFile } from '@/api/data';

//配置路由
export default new VueRouter({
    //配置路由
    routes: [
        {
            path: '/home',
            component: Home,
            meta: {
                curtab: 0
            },
            name: 'home'
        },
        {
            path: '/contactus/:curindex?',
            component: Contactus,
            meta: {
                curtab: 1
            },
            name: 'contactus'
        },
        {
            path: '/gitsuccess',
            component: GITSuccess,
            meta: {
                curtab: 1
            },
            name: 'gitsuccess'
        },
        {
            path: '/decoration',
            component: Decoration,
            name: 'decoration',
            meta: {
                hideHeader: true,
                hideFooter: true,
            }
        },
        {
            path: '/decorationsuccess',
            component: DecorationSuccess,
            meta: {
                hideHeader: true,
                hideFooter: true,
            },
            name: 'decorationsuccess'
        },

        //重定向,在项目跑起来的时候,访问/,立马让他定向到首页
        {
            path: '*',
            redirect: '/home'
        }



    ]
})

