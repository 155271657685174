import request from '@/utils/request';


export const reqGetParamList = (page, limit) => request({ url: `/admin/globalmgt/GetParamList?page=${page}&limit=${limit}`, method: 'get' });


export const reqAddOrUpdateParam= (Param) => {
    if (Param.id) {
        return request({ url: `/admin/globalmgt/UpdateParam`, method: 'put', data: Param });
    }
    else {
        return request({ url: `/admin/globalmgt/AddParam`, method: 'post', data: Param });
    }
};


export const reqDeleteParam = (id) => request({ url: `/admin/globalmgt/DeleteParam?id=${id}`, method: 'delete' });


export const reqGetParamListByNameList = (namelist) => request({ url: `/admin/globalmgt/GetParamListByNameList?namelist=${namelist}`, method: 'get' });


