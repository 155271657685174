<template>
  <!-- <div class="homeGIT_content">


    <div class="homeGIT_line"></div>


    
    <div class="homeGIT_t1">
      <a class="homeGIT_t1_a1">Get in touch with us</a>
    </div>
    <p class="homeGIT_t2_a1">Kindly fill in the contact form below and a senior member of our team will be in touch with you very soon. If you’d prefer to talk to someone right away, simply give us a call at <span class="homeGIT_highlight">+65 3138 9182</span> during office hours.</p>






    <div class="homeGIT_info-list">
      <div class="homeGIT_info-cont">
        <div class="homeGIT_info-title">First Name</div>
        <input class="homeGIT_info-edit" v-model="first_name">
      </div>  
      <div class="homeGIT_info-cont">
        <div class="homeGIT_info-title">Last Name</div>
        <input class="homeGIT_info-edit" v-model="last_name">
      </div>
      <div class="homeGIT_info-cont">
        <div class="homeGIT_info-title">Company Name</div>
        <input class="homeGIT_info-edit" v-model="company_name">
      </div>  
      <div class="homeGIT_info-cont">
        <div class="homeGIT_info-title">Email Address<span class="homeGIT_highlight">*</span></div>
        <input class="homeGIT_info-edit" v-model="email_address">
      </div>


    </div>

    <div class="homeGIT_info-cont1">
      <div class="homeGIT_info-title">Message</div>
      <textarea class="homeGIT_info-mledit" v-model="message"/>
    </div>


    <button class="homeGIT_info-btn" @click="clickCommit()">Get In Touch</button>


  </div> -->

  <div class="homeGIT_content">

    <div class="container">

      <div class="row">
        <div class="col-md-12">  
          <div class="homeGIT_line"></div>
        </div>
        <div class="col-md-12">  
          <p class="homeGIT_t1_a1">{{ $t('getintouch.git_t1_1') }}</p>  
        </div>  
        <div class="col-md-12">  
          <p class="homeGIT_t2_a1">{{ $t('getintouch.git_t1_2_1') }}<span class="homeGIT_highlight">{{GetParam('GetInTouchCall')}}</span>{{ $t('getintouch.git_t1_2_2') }}</p>
        </div>
      </div>  


      <div class="row">
        <!-- <div class="homeGIT_info-list"> -->
          <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">{{ $t('getintouch.git_t1_3_1') }}</div>
            <input class="homeGIT_info-edit" v-model="first_name">
          </div>
          <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">{{ $t('getintouch.git_t1_3_2') }}</div>
            <input class="homeGIT_info-edit" v-model="last_name">
          </div>
        <!-- </div> -->
      </div>


      <div class="row">
        <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">{{ $t('getintouch.git_t1_3_3') }}</div>
            <input class="homeGIT_info-edit" v-model="company_name">
        </div>  
        <div class="col-md-6 homeGIT_info-cont">
            <div class="homeGIT_info-title">{{ $t('getintouch.git_t1_3_4') }}<span class="homeGIT_highlight">*</span></div>
            <input class="homeGIT_info-edit" v-model="email_address">
        </div>
      </div>



      <div class="row">
        <div class="col-md-12 homeGIT_info-cont1">
          <div class="homeGIT_info-title">{{ $t('getintouch.git_t1_3_5') }}</div>
          <textarea class="homeGIT_info-mledit" v-model="message"/>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <button class="rounded-button" @click="clickCommit()">{{ $t('getintouch.git_btn_1') }}</button>
        </div>
      </div>



    </div>



  </div>



</template>

<script>


export default {
  data() {
    return {
      globalparam: [],
      answerlist_open: [false, false, false, false],
      first_name: '',
      last_name: '',
      company_name: '',
      email_address: '',
      message: '',
    };
  },

  computed: {
    list_group() {
      return this.sliceList(this.list);
    },
  },

  mounted() {
    this.GetParamListByNameList();
  },

  methods: {
    async GetParamListByNameList() {
      let namelist = "GetInTouchCall,GetInTouchMail";
      let result = await this.$API.globalparam.reqGetParamListByNameList(namelist);
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
      }
    },
    GetParam(paramname){
      for(let i=0;i<this.globalparam.length;i++){
        if(this.globalparam[i].paramname == paramname)
          return this.globalparam[i].paramvalue;
      }
      return "";
    },


    async clickCommit(){
      // alert(this.first_name);
      // alert(this.last_name);
      // alert(this.company_name);
      // alert(this.email_address);
      // alert(this.message);



      let data = {firstname: this.first_name, lastname: this.last_name, companyname: this.company_name, emailaddress: this.email_address, message: this.message};
      // alert(JSON.stringify(data));

      let result = await this.$API.contactus.AppendGetInTouch(data);
      // alert(JSON.stringify(result));
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          // this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
          // alert("Thank you for leaving your contact information. Relevant personnel will contact you in the future. Thank you!");
          //跳转
          this.$router.push({name: 'gitsuccess'})
      }


    },


    

    
  },
};
</script>

<style scoped>


.homeGIT_content{
  padding: 50px 20px 50px 20px;
}




.homeGIT_line {  
  height: 1px;  
  background-color: black;
  margin-bottom: 5px;
}



.homeGIT_t1{
  display: flex;
  flex-direction: column;  
  align-items: left; /* 垂直居中 */  
  justify-content: left; /* 水平居中 */
}


.homeGIT_t1_a1{
  color: orange; 
  font-size: 24px;
  text-align: left;
  margin-bottom: 10px;  
}


.homeGIT_t2{
  display: flex;
  flex-direction: column;  
  align-items: left; /* 左对齐 */  
  justify-content: center; /* 水平居中 */
  margin: 50px 0 50px 0;
}


.homeGIT_t2_t1{
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}


.homeGIT_t2_a1{
  font-size: 18px;
  line-height: 2;
  margin-top: 0px;
}



.homeGIT_highlight {  
    color: orangered;  
    font-weight: bold;  
}  








.homeGIT_info-list{
  margin-top: 50px;
  display: flex;  
  flex-wrap: wrap;
  justify-content: space-between; 
  row-gap: 20px;
}

.homeGIT_info-cont {  
  margin-bottom: 10px;
  padding-right: 20px;
}  


.homeGIT_info-cont1 {
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;  
}  


.homeGIT_info-title {  
  font-weight: bold;  
  font-size: 14px;
}  
  
.homeGIT_info-edit {  
  margin-top: 10px;
  height: 35px;
  width: 100%;
  font-size: 16px;
}

.homeGIT_info-mledit {  
  margin-top: 10px;
  height: 70px;
  width: 100%;
  font-size: 16px;
  
}


.homeGIT_info-btn{
  margin-top: 5px;
  background-color: orangered;
  font-size: 16px;
  color: white;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}


.rounded-button {
    display: inline-block;  
    padding: 10px 20px;  
    font-size: 16px;  
    color: #ffffff;  
    background-color: orangered; /* 可以根据需要更改背景颜色 */  
    border: none;  
    border-radius: 50px; /* 圆角的大小，这里设置为50px以使其成为一个完全的圆形按钮 */  
    cursor: pointer;  
    text-align: center;  
    text-decoration: none;  
    outline: none;  
    transition: background-color 0.3s ease; /* 添加过渡效果，使按钮在被点击时看起来更平滑 */
    width: 150px;
}  
  
.rounded-button:hover {  
    background-color: #45a049; /* 鼠标悬停时的背景颜色 */  
}  
  
.rounded-button:active {  
    background-color: #45a049; /* 按钮被点击时的背景颜色 */  
    box-shadow: 0 5px #666; /* 添加阴影效果，使按钮在被点击时看起来更立体 */  
    transform: translateY(4px); /* 向下移动按钮，增加点击效果 */  
}



</style>