<template>
  <GetInTouch />
</template>


<script>

import GetInTouch from '@/components/GetInTouch'


export default {

  components: {
    GetInTouch,
  },


  computed: {

  },

  data(){
    return {
 
    };
  }, 

  mounted(){

  },

  methods: {

  },
};
</script>

<style>
</style>