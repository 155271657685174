<template>
    <footer class="foot-container">


      <div class="container">
        <div class="row">
          <div class="col-md-12 foot-item">
            <p class="foot-item-p1"><img src="images/comm/main_logo.png" class="main_log"></p>
            <!-- <p class="foot-item-t1">{{ $t('footer.foot_t1_1') }}</p> -->
            <p class="foot-item-a1">{{ $t('footer.foot_t1_2') }}  info@carek.nl</p>
            <!-- <p class="foot-item-a1">{{GetParam('OfficeMail')}}</p> -->
          </div>

          <!-- <div class="col-md-4 foot-item">
            <p class="foot-item-t1"></p>
            <p class="foot-item-a1">{{GetParam('OfficeMail')}}</p>
            <p class="foot-item-t1">{{ $t('footer.foot_t2_1') }}</p>
            <p class="foot-item-a1">{{ $t('footer.foot_t2_2') }}{{GetParam('OfficeLocated')}}</p>
            <p class="foot-item-a1">{{ $t('footer.foot_t2_3') }}{{GetParam('OfficeAddress')}}</p>
            <p class="foot-item-b1">•</p>
          </div> -->

          <!-- <div class="col-md-4 foot-item">
            <p class="foot-item-b1">•</p>
            <p class="foot-item-b1">•</p>
            <p class="foot-item-b1">•</p>
            <p class="foot-item-b1">•</p>
          </div> -->
        </div>

        <div class="foot-item foot-item-bottom"></div>



        <div class="row">
          <div class="col-md-6 foot-item">
            <p class="foot-item-a1">{{ $t('footer.foot_bottom_1') }}</p>
          </div>

          <!-- <div class="col-md-4 foot-item">
            <p class="foot-item-b1">•</p>
          </div> -->

          <div class="col-md-6 foot-item">
            <p class="foot-item-a1">{{ $t('footer.foot_bottom_2') }}</p>
          </div>
        </div>

      </div>


      <!-- <div class="col-md-4 foot-item">
        <img src="./images/footer_logo.png">
      </div>

      <div class="col-md-4 foot-item">
        <p class="foot-item-t1">Meet Up With Us</p>
      </div>

      <div class="col-md-4 foot-item">

      </div>




      <div class="col-md-4 foot-item">
        <p class="foot-item-t1">Contact Us</p>
      </div>

      <div class="col-md-4 foot-item">
        <p class="foot-item-a1">Our office is located in bustling Chinatown:</p>
      </div>

      <div class="col-md-4 foot-item">

      </div>





      <div class="col-md-4 foot-item">
        <p class="foot-item-a1">Reach out to us simply by  emailing</p>
      </div>

      <div class="col-md-4 foot-item">
        <p class="foot-item-a1">Office: 195 Pearl’s Hill Terrace, #02-12, S168976</p>
      </div>

      <div class="col-md-4 foot-item">

      </div>



      <div class="col-md-4 foot-item">
        <p class="foot-item-a1">enquiry@derolbc.com.sg</p>
      </div>

      <div class="col-md-4 foot-item">

      </div>

      <div class="col-md-4 foot-item">

      </div>



      <div class="col-md-4 foot-item foot-item-bottom">
        <p class="foot-item-a1">• Copyright © 2023 | All Rights Reserved</p>
      </div>

      <div class="col-md-4 foot-item foot-item-bottom">

      </div>

      <div class="col-md-4 foot-item foot-item-bottom">
        <p class="foot-item-a1">• Made With ❤️ By DEROL! Brand Communications</p>
      </div> -->




    </footer>

</template>

<script>
export default {


data(){
    return {
      globalparam: [],
    };
  }, 

  mounted(){
    this.GetParamListByNameList();
  },


  methods: {
    async GetParamListByNameList() {
      let namelist = "OfficeMail,OfficeAddress,OfficeLocated";
      let result = await this.$API.globalparam.reqGetParamListByNameList(namelist);
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
      }
    },
    GetParam(paramname){
      for(let i=0;i<this.globalparam.length;i++){
        if(this.globalparam[i].paramname == paramname)
          return this.globalparam[i].paramvalue;
      }
      return "";
    },


    


  },


}
</script>

<style scoped>

/* #footer { margin-top:30px; padding-top:30px; background-color:#27303f;}
.links{ padding-left:18px; margin-bottom:45px;}
.links ul{ _display:inline-block; _width:100%;}
.links ul li{ float:left; padding-left:40px; width:170px;}
.links li .dt{ line-height:25px; font-size:18px; color:#fff; margin-bottom:4px;}
.links li .dd{ line-height:20px; color:#fff; font-family:"宋体",serif; margin-bottom:16px;}
.links li .link{ line-height:25px;}
.links li .link a{ color:#fff; display:block;}
.links li .link a:hover{ color:#fc5310;}

.links li .link .weixin img { display: none; position: absolute; bottom:22px; left: 0;}
.links li .tell{ color:#fff; font-size:18px; font-family:Ebrima,Arial;}
#footer .foot{ padding:18px 0; background-color:#27303f;}
#footer .foot .container{ width:1190px; _height:44px; line-height:22px; color:#fff;}
#footer .foot a{ color:#fff;}
#footer .foot a:hover{ color:#fc5310;}
#footer .foot .fnav{ padding-right:94px; text-align:right;}
#footer .foot .fnav a{ margin:0 10px;}
#footer .foot .copyright{text-align:right; padding-right:108px;} */


.main_log{
  width: 219px;
  height: 57px;
}



.foot-container{
  background-color: black;
}

.foot-item {  
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 5px;
}



.foot-item-p1{
  height: 50px;
  text-align: left;
}


.foot-item-t1{
  height: 50px;
  color: white; 
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}


.foot-item-a1{
  /* margin-top: 30px; */
  /* height: 50px; */
  color: white; 
  font-size: 16px;
  text-align: left;
}

.foot-item-b1{
  height: 50px;
  color: black; 
  font-size: 16px;
  text-align: left;
}

/* .foot-item-bottom{
  margin-top: 30px;
} */



</style>