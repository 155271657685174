<template>
    <div>
        <Header v-show="!$route.meta.hideHeader"></Header>
        <router-view></router-view>
        <Footer v-show="!$route.meta.hideFooter"></Footer>
    </div>
</template>

<script>
    //组件
    import Header from './components/Header'
    import Footer from './components/Footer'

    export default {
        name: 'app',
        data(){
            return {
                // header_show: true,
                // footer_show: true,
            }
        },
        components: {
            Header,
            Footer,
        },
        methods: {
            // //是否显示头部
            // setheader:function(value){
            //     this.header_show = value;
            // },
            // //是否显示底部
            // setfooter:function(value){
            //     this.footer_show = value;
            // }

        }


    };
</script>

<style>
</style>

