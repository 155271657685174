<template>
  <div class="homefaq_content">

    <div class="container">

      <div class="row homefaq_t1">
        <p class="col-md-12 homefaq_t1_a1">{{ $t('homefaq.homefaq_title') }}</p>
      </div>


      <div class="row">
        <div class="col-md-6 faq-container">
          <div class="faq-quest-cont" @click="clickAsk(0)">
            <!-- <div class="faq-question">How does DEROL! approach TikTok marketing?</div>
            <i :class="answerlist_open[0]?'elementor-tab-content active':'elementor-tab-content'"></i> -->
            <div class="faq-question">{{ $t('homefaq.homefaq_ask1') }}</div>
            <div v-if="answerlist_open[0]==true" class="elementor-tab-content"><img src="images/comm/collapse.png" class="faq-img-expcol"></div>
            <div v-else class="elementor-tab-content"><img src="images/comm/expand.png" class="faq-img-expcol"></div>
          </div>
          <!-- <div :class="answerlist_open[0]?'faq-answer active':'faq-answer'">We focus on creating engaging, original content that resonates with your target audience, ensuring optimal campaign performance.</div> -->
          <div :class="answerlist_open[0]?'faq-answer active':'faq-answer'">{{ $t('homefaq.homefaq_answer1') }}</div>
        </div>  
        <div class="col-md-6 faq-container">
          <div class="faq-quest-cont" @click="clickAsk(1)">
            <!-- <div class="faq-question">How do you measure success on TikTok?</div>
            <i :class="answerlist_open[1]?'elementor-tab-content active':'elementor-tab-content'"></i> -->
            <div class="faq-question">{{ $t('homefaq.homefaq_ask2') }}</div>
            <div v-if="answerlist_open[1]==true" class="elementor-tab-content"><img src="images/comm/collapse.png" class="faq-img-expcol"></div>
            <div v-else class="elementor-tab-content"><img src="images/comm/expand.png" class="faq-img-expcol"></div>
          </div>
          <!-- <div :class="answerlist_open[1]?'faq-answer active':'faq-answer'">As the leading TikTok Marketing Agency in the Netherlands, we at DEROL! analyze metrics such as engagement rate, view count, follower growth, and conversion rates to measure the success of your campaigns.</div> -->
          <div :class="answerlist_open[1]?'faq-answer active':'faq-answer'">{{ $t('homefaq.homefaq_answer2') }}</div>
        </div>  
      </div>


      <div class="row">
        <div class="col-md-6 faq-container">
          <div class="faq-quest-cont" @click="clickAsk(2)">
            <!-- <div class="faq-question">What kind of businesses can benefit from TikTok marketing?</div>
            <i :class="answerlist_open[2]?'elementor-tab-content active':'elementor-tab-content'"></i> -->
            <div class="faq-question">{{ $t('homefaq.homefaq_ask3') }}</div>
            <div v-if="answerlist_open[2]==true" class="elementor-tab-content"><img src="images/comm/collapse.png" class="faq-img-expcol"></div>
            <div v-else class="elementor-tab-content"><img src="images/comm/expand.png" class="faq-img-expcol"></div>
          </div>
          <!-- <div :class="answerlist_open[2]?'faq-answer active':'faq-answer'">Businesses in sectors ranging from retail to education can leverage TikTok’s dynamic platform to reach new audiences and boost engagement.</div> -->
          <div :class="answerlist_open[2]?'faq-answer active':'faq-answer'">{{ $t('homefaq.homefaq_answer3') }}</div>
        </div>
        <div class="col-md-6 faq-container">
          <div class="faq-quest-cont" @click="clickAsk(3)">
            <!-- <div class="faq-question">How does DEROL! handle content creation?</div>
            <i :class="answerlist_open[3]?'elementor-tab-content active':'elementor-tab-content'"></i> -->
            <div class="faq-question">{{ $t('homefaq.homefaq_ask4') }}</div>
            <div v-if="answerlist_open[3]==true" class="elementor-tab-content"><img src="images/comm/collapse.png" class="faq-img-expcol"></div>
            <div v-else class="elementor-tab-content"><img src="images/comm/expand.png" class="faq-img-expcol"></div>
          </div>
          <!-- <div :class="answerlist_open[3]?'faq-answer active':'faq-answer'">Our creative team collaborates with you to craft authentic, engaging content that aligns with your brand and resonates with the TikTok community.</div> -->
          <div :class="answerlist_open[3]?'faq-answer active':'faq-answer'">{{ $t('homefaq.homefaq_answer4') }}</div>
        </div>
      </div>


      <!-- <div
        v-for="(itemsgrp, index_i) in list_group"
        :key="index_i"
        class="row"
      >
        <div v-for="(item, index) in itemsgrp.items"
            class="col-md-6 faq-container"
            :key="index">
          <div class="faq-quest-cont" @click="clickAsk(item)">
            <div class="faq-question">{{item.asktitle}}</div>
            <i :class="item.isopen==true?'elementor-tab-content active':'elementor-tab-content'"></i>
          </div>
          <div :class="item.isopen==true?'faq-answer active':'faq-answer'" v-html="item.answercont"></div>
        </div>
      </div> -->




    </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
      answerlist_open: [true, true, true, true],

      //代表的分页器第几页
      page: 1,
      //当前页数展示数据条数
      limit: 4,
      //总共数据条数
      total: 0,
      //列表展示的数据
      list: [],
    };
  },

  computed: {
    list_group() {
      return this.sliceList(this.list);
    },
  },

  mounted() {
    // this.getPageList();
  },

  methods: {
    async getPageList(pager = 1) {
      this.page = pager;
      //解构出参数
      const { page, limit } = this;
      //获取轮播列表的接口
      //当你向服务器发请求的时候，这个函数需要带参数，因此老师在data当中初始化两个字段，代表给服务器传递参数
      let result = await this.$API.homepaskans.reqGetAskAnsList(page, limit);
      // alert(JSON.stringify(result.data));
      if (result.code == 200) {

        //分别是展示数据总条数与列表展示的数据
        this.total = result.data.total;
        this.list = result.data.list;

        //初始化是否折叠打开
        for (let i = 0; i < this.list; i ++) {
          this.list[i].isopen = false;
          // alert(JSON.stringify(this.list[i]));
        }


        // this.$nextTick(function () {
          // this.initSwiper();
        // });
      }
    },

    sliceList(data) {
      let n = 2;
      var result = [];
      for (var i = 0; i < data.length; i += n) {
        var itemsgrp = {};
        itemsgrp.items = data.slice(i, i + n);
        result.push(itemsgrp);
      }
      // alert(JSON.stringify(result));
      // alert(result.length);
      return result;
    },


    // clickAsk(item){
    //   // alert(JSON.stringify(item));
    //   item.isopen = !item.isopen;
    //   this.$forceUpdate();
    // },

    clickAsk(index){
      this.answerlist_open[index] = !this.answerlist_open[index];
      // alert(this.answerlist_open[index]);
      this.$forceUpdate();
    },

    
  },
};
</script>

<style scoped>


.homefaq_content{
  /* background-color: #FFF8F0; */
  /* width: 100%;  
  background-color: #F2F2F2;
  padding: 100px 0 100px 0; */
}


.homefaq_t1{
  display: flex;
  flex-direction: column;  
  align-items: center; /* 垂直居中 */  
  justify-content: center; /* 水平居中 */
}


.homefaq_t1_a1{
  color: black; 
  font-size: 24px;
  text-align: center;
  margin-bottom: 50px;  
}




.faq-list{
  padding: 0 150px;
  display: flex;  
  flex-wrap: wrap;
  justify-content: space-between;  
  row-gap: 30px;  
}

.faq-container {  
  margin-bottom: 20px;
  /* flex: 0 0 calc(50% - 20px);  
  max-width: calc(50% - 20px);  
  padding: 5px;  
  box-sizing: border-box;   */
}  


.faq-quest-cont {  
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
} 


.faq-question {  
  font-weight: bold;  
  font-size: 14px;
}  
  
.faq-answer {  
  display: none;  
  margin-top: 10px;
  font-size: 14px;
}  
  
.faq-answer.active {  
  display: block;  
}  


.faq-img-expcol {  
  width: 24px;
  height: 24px;
} 




/* .elementor-toggle-icon .elementor-toggle-icon-closed{
  display:block
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-opened{
  
  display:none
}

.elementor-toggle .elementor-tab-title.elementor-active{
  border-bottom:none
}

.elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-closed{
  display:none
}

.elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-opened{
  display:block
} */







/* .elementor-tab-content{
  width: 6px;  
  height: 12px;  
  border: 2px solid black;
  margin-right: 20px;
} 

.elementor-tab-content.active{
  border: 2px solid red;
}

*/



.elementor-tab-content{
  /* width: 12px;
  height: 12px;  */
  font-weight: 600;
  color: red;
  margin-right: 20px;
}


.elementor-tab-content.active{
  /* border: 2px solid red; */
}





</style>