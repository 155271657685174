<template>
  <div class="partners_content">



    <div class="container">

      <div class="row partners_t1">
        <p class="col-md-12 partners_t1_a1">Some of the brands we work with</p>
      </div>
      
      

      <div
        v-for="(partners, index_i) in list_group"
        :key="index_i"
        class="row"
      >

        <div v-for="(partner, index) in partners.partneritems"
            class="col-md-3 image-box"
            :key="index">  
          <img :src="$host+partner.ImgUrl" class="img-responsive">  
        </div>  


      </div>


      <!-- <div class="row">  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_1.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_2.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_3.png" class="img-responsive">  
        </div>
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_4.png" class="img-responsive">  
        </div>
      </div>


      <div class="row">  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_5.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_6.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_7.png" class="img-responsive">  
        </div>
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_8.png" class="img-responsive">  
        </div>
      </div>



      <div class="row">  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_9.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_10.png" class="img-responsive">  
        </div>  
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_11.png" class="img-responsive">  
        </div>
        <div class="col-md-3 image-box">  
          <img src="images/partners/partners_12.png" class="img-responsive">  
        </div>
      </div> -->



    </div>



  </div>



</template>

<script>


export default {
  data() {
    return {

      //代表的分页器第几页
      page: 1,
      //当前页数展示数据条数
      limit: 12,
      //总共数据条数
      total: 0,
      //列表展示的数据
      list: [],
    };
  },

  computed: {
    list_group() {
      return this.sliceList(this.list);
    },
  },

  mounted() {
    this.getPageList();
  },

  methods: {
    async getPageList(pager = 1) {
      this.page = pager;
      //解构出参数
      const { page, limit } = this;
      //获取轮播列表的接口
      //当你向服务器发请求的时候，这个函数需要带参数，因此老师在data当中初始化两个字段，代表给服务器传递参数
      let result = await this.$API.homepartner.reqGetPartnerList(page, limit);
      // alert(JSON.stringify(result.data));
      if (result.code == 200) {
        //分别是展示数据总条数与列表展示的数据
        this.total = result.data.total;
        this.list = result.data.list;
        // this.$nextTick(function () {
          // this.initSwiper();
        // });
      }
    },

    sliceList(data) {
      let n = 4;
      var result = [];
      for (var i = 0; i < data.length; i += n) {
        var item = {};
        item.partneritems = data.slice(i, i + n);
        result.push(item);
      }
      // alert(JSON.stringify(result));
      // alert(result.length);
      return result;
    },


    
  },
};
</script>

<style scoped>


.partners_content{
  width: 100%;  

}


.partners_t1{
  display: flex;
  flex-direction: column;  
  align-items: center; /* 垂直居中 */  
  justify-content: center; /* 水平居中 */
}


.partners_t1_a1{
  color: black; 
  font-size: 24px;
  text-align: center;
  margin-bottom: 50px;  
}



.partners_pic1{
  /* display: flex;  
  flex-wrap: wrap;
  justify-content: space-between;  
  row-gap: 30px;   */
}


.image-box {  
  /* flex: 0 0 calc(25% - 20px);  
  max-width: calc(25% - 20px);  
  padding: 5px;  
  box-sizing: border-box;   */
  margin-bottom: 20px;
}  
  
/* .image-box img {  
  width: 200px;
  height: 60px;  
}   */





</style>