//将模块请求的接口函数统一暴露


import * as globalparam from './globalmgt/param'
import * as contactus from './contact/contactus'
import * as decoration from './decoration/decoration'



import * as homepartner from './homemgt/partner'
import * as homepaskans from './homemgt/askans'





//对外暴露
export default {
    globalparam,
    contactus,
    decoration,



    homepartner,
    homepaskans,

}


