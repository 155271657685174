import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import messages from './locales'; // 导入本地化消息对象






Vue.config.productionTip = false;


//引入路由
import router from '@/router';
import http from 'axios'



//获取host配置
import config from '@/config'
const host = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
Vue.prototype.$host = host;


//引入多语言插件
Vue.use(VueI18n);



import Cookies from 'js-cookie';
var locale_lang = Cookies.get('locale_lang');
// alert(locale_lang);
if(locale_lang == undefined) locale_lang = "nl";

const i18n = new VueI18n({  
  locale: locale_lang, // 默认语言  
  messages, // 语言包  
});



//引入相关API请求接口
import API from '@/api'
//组件实例的原型的原型指向的是Vue. prototype
//任意组件可以使用API相关的接口
Vue.prototype.$API = API;



Vue.prototype.$http = http


new Vue({
    i18n, // 注入 i18n 实例
    render: h => h(App),
    //注册路由：底下的写法KV一致省V[router小写]
    //注册路由信息:当这里书写router的时候，组件身上都拥有$route ,$router属性
    //$route:一般获取路由信息【路径、query.params等等
    //$router:一般进行编程式导航进行路由跳转【push|replace】
    router
}).$mount('#app');
