<template>

  <div class="container">
      <div class="row" style="margin-top: 50px;">
        <div class="col-md-12">
          <h3>
            <img src="images/right.png" class="img-responsive">
            Bedankt voor het achterlaten van uw decoratie informatie. Relevant personeel zal in de toekomst contact met u opnemen. Dank u!
          </h3>
        </div>
        <div class="col-md-12">
          <button class="homeGIT_info-btn" @click="clickReturn()">Terug naar decoratie</button>
        </div>
      </div>
  </div>


  <!-- <div class="opersuccess">
      <div class="success">
        <h3>
          <img src="./images/right.png" width="48px" height="48px">
          Thank you for leaving your contact information. Relevant personnel will contact you in the future. Thank you!
        </h3>
        <div class="operdetail">
          <p class="button">
            <router-link class="btn-goshop" to="/">Return to ABOUT</router-link>
          </p>
        </div>
      </div>
  </div> -->
</template>

<script>
  export default {
    name: 'GITSuccess',


    methods: {
      async clickReturn(){
        //跳转
        this.$router.push({name: 'decoration'})


      },

      
    },

  }
</script>

<style lang="less" scoped>

.homeGIT_info-btn{
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: orangered;
  font-size: 16px;
  color: white;
  border: none;
  height: 40px;
  // padding: 10px 20px 10px 20px;
  cursor: pointer;
}

  .opersuccess {
    margin: 20px auto;
    padding: 25px;
    border: 1px solid rgb(211, 211, 211);
    width: 1200px;
    width: 1148px;

    .success {
      width: 500px;
      margin: 0 auto;

      h3 {
        margin: 20px 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;

        img {
          max-width: 100%;
          vertical-align: middle;
          border: 0;
          margin-right: 14px;
        }
      }

      .operdetail {
        margin-left: 66px;
        font-size: 15px;

        .button {
          margin: 30px 0;
          line-height: 26px;

          a {
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 2px;
            user-select: none;
            font-size: 18px;
            padding: 4px 20px;
            line-height: 22px;
            text-decoration: none;

            &.btn-look {
              margin-right: 13px;
              color: #fff;
              background-color: #e1251b;
              border: 1px solid #e1251b;
            }

            &.btn-goshop {
              color: #666;
              background-color: #eee;
              border: 1px solid #e1e1e1;
            }
          }
        }
      }
    }
  }
</style>