import request from '@/utils/request';


export const reqGetAskAnsList = (page, limit) => request({ url: `/admin/homemgt/GetAskAnsList?page=${page}&limit=${limit}`, method: 'get' });


export const reqAddOrUpdateAskAns= (AskAns) => {
    if (AskAns.id) {
        return request({ url: `/admin/homemgt/UpdateAskAns`, method: 'put', data: AskAns });
    }
    else {
        return request({ url: `/admin/homemgt/AddAskAns`, method: 'post', data: AskAns });
    }
};


export const reqDeleteAskAns = (id) => request({ url: `/admin/homemgt/DeleteAskAns?id=${id}`, method: 'delete' });




export const reqGetRecommendAskAnsList = (page, limit) => request({ url: `/admin/homemgt/GetRecommendAskAnsList?page=${page}&limit=${limit}`, method: 'get' });

export const reqGetHotAskAnsList = (page, limit) => request({ url: `/admin/homemgt/GetHotAskAnsList?page=${page}&limit=${limit}`, method: 'get' });

export const reqViewAskAnsByID = (id) => request({ url: `/admin/homemgt/ViewAskAnsByID?id=${id}`, method: 'get' });


