import request from '@/utils/request';


export const reqGetPartnerList = (page, limit) => request({ url: `/admin/homemgt/GetPartnerList?page=${page}&limit=${limit}`, method: 'get' });


export const reqAddOrUpdatePartner= (Partner) => {
    if (Partner.id) {
        return request({ url: `/admin/homemgt/UpdatePartner`, method: 'put', data: Partner });
    }
    else {
        return request({ url: `/admin/homemgt/AddPartner`, method: 'post', data: Partner });
    }
};


export const reqDeletePartner = (id) => request({ url: `/admin/homemgt/DeletePartner?id=${id}`, method: 'delete' });


