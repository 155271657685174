<template>

  <div class="home_content">

    <div class="home_t1">
      <div class="container">
        <div class="row">
          <p class="col-md-12 home_t1_a1">{{ $t('homepage.home_t1_1') }}</p>
          <p class="col-md-12 home_t1_a2">{{ $t('homepage.home_t1_2') }}</p>
        </div>
      </div>
    </div>


  <div class="home_t2">
      <div class="container">

        
        <!-- <div class="row">
          <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t2_1') }}</p>
          <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t2_2') }}</p>
        </div> -->




        <div class="row">
            <div class="col-md-8">
              <p class="col-md-12 home_t2_t0">{{ $t('homepage.home_t2_0') }}</p>
              <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t2_1') }}</p>
              <!-- <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t2_2') }}</p> -->
              <p  v-if="isMacOS() === true" class="col-md-12 home_t2_a1_big">{{ $t('homepage.home_t2_2') }}</p>
              <p v-else class="col-md-12 home_t2_a1">{{ $t('homepage.home_t2_2') }}</p>
            </div>
            <!-- <div class="col-md-4">  
            </div>   -->
            <div class="col-md-4">  
                <img src="./images/Marketing_img.png" class="img-fluid">  
            </div>   
        </div>


        <!-- <div class="row">
          <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t3_1') }}</p>
          <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t3_2') }}</p>
          <ul>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_1') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_2') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_3') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_4') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_5') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_6') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_7') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_8') }}</li>
          </ul>
        </div> -->

        <div v-if="isMobile==false" style="margin-top: 20px;"></div>

        <div class="row">
            <div v-if="isMobile==false" class="col-md-4">  
                <img src="./images/Services_img.png" class="img-fluid">  
            </div>  
            <!-- <div v-if="isMobile==false" class="col-md-4">  
            </div>   -->
            <div class="col-md-8 d-flex justify-content-center">
              <div class="row rounded-box">
                <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t3_1') }}</p>
                <!-- <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t3_2') }}</p> -->
                <p v-if="isMacOS() === true" class="col-md-12 home_t2_a1_big">{{ $t('homepage.home_t3_2') }}</p>
                <p v-else class="col-md-12 home_t2_a1">{{ $t('homepage.home_t3_2') }}</p>
                <ul>
                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_1') }}</li>
                  <li v-else class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_1') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_2') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_2') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_3') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_3') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_4') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_4') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_5') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_5') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_6') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_6') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_7') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_7') }}</li>

                  <li v-if="isMacOS() === true" class="col-md-12 home_t2_l1_big">{{ $t('homepage.home_t3_3_8') }}</li>
                  <li v-else class="col-md-12 home_t2_l1">{{ $t('homepage.home_t3_3_8') }}</li>
                </ul>
              </div>
            </div>
            <!-- <div v-if="isMobile==true" class="col-md-4">  
            </div>  -->
            <div v-if="isMobile==true" class="col-md-4">  
                <img src="./images/Services_img.png" class="img-fluid">  
            </div>  
        </div>






        <!-- <div class="row">
          <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t4_1') }}</p>
          <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t4_2') }}</p>
          <ul>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_1') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_2') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_3') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_4') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_5') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_6') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_7') }}</li>
            <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_8') }}</li>
          </ul>
        </div> -->


        <div style="margin-top: 20px;"></div>

        <div class="row">
            <div class="col-md-8">  
              <p class="col-md-12 home_t2_t1">{{ $t('homepage.home_t4_1') }}</p>
              <p class="col-md-12 home_t2_a1">{{ $t('homepage.home_t4_2') }}</p>
              <ul>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_1') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_2') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_3') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_4') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_5') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_6') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_7') }}</li>
                <li class="col-md-12 home_t2_l1">{{ $t('homepage.home_t4_3_8') }}</li>
              </ul>
            </div>
            <!-- <div class="col-md-4">  
            </div>   -->
            <div class="col-md-4">  
                <img v-if="isMobile==false" src="./images/Industries_img.png" class="img-fluid">  
            </div>  
        </div>

        <div style="margin-bottom: 30px"></div>


      </div>





      <!-- 目前没有客户，暂时隐藏掉 -->
      <!-- <Partners /> -->
    </div>



    <HomeFAQ />
    <GetInTouch />


  </div>
</template>

<script>
import Partners from "@/pages/Home/Partners";
import HomeFAQ from "@/pages/Home/HomeFAQ";
import GetInTouch from '@/components/GetInTouch';

import 'bootstrap/dist/css/bootstrap.css';



export default {

  components: {
    Partners,
    HomeFAQ,
    GetInTouch,
  },




  data(){
    return {
      globalparam: [],
      isMobile: false,
    };
  }, 

  mounted(){
    // this.GetParamListByNameList();
    this.detectDeviceType();
    // alert(this.isMobile);
  },


  methods:{
    detectDeviceType() {  
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
  
      // Windows Phone must come first because its UA also contains "Android"  
      if (/windows phone/i.test(userAgent)) {  
        this.isMobile = true;  
      }  
  
      // iOS devices  
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {  
        this.isMobile = true;  
      }  
  
      // Android devices  
      if (/android/i.test(userAgent)) {  
        this.isMobile = true;  
      }  
  
      // Other mobile devices  
      if (/BlackBerry|webOS|IEMobile|Opera Mini/i.test(userAgent)) {  
        this.isMobile = true;  
      }  
  
      // If none of the above matched, it's probably a desktop browser  
      if (!this.isMobile) {  
        this.isMobile = false;  
      }  
    },
    async GetParamListByNameList() {
      let namelist = "GetInTouchCall,GetInTouchMail";
      let result = await this.$API.globalparam.reqGetParamListByNameList(namelist);
      if (result.code == 200) {
          // alert(JSON.stringify(result.data));
          this.globalparam = result.data.list;
          // alert(JSON.stringify(this.globalparam));
      }
    },
    GetParam(paramname){
      for(let i=0;i<this.globalparam.length;i++){
        if(this.globalparam[i].paramname == paramname)
          return this.globalparam[i].paramvalue;
      }
      return "";
    },
    isMacOS() {
      return /Mac OS X/i.test(navigator.userAgent);
    },


    toAboutus(){
      this.$router.push('/aboutus');
    },



  }

};
</script>

<style>

.home_content{
  /* width: 100%; */
}


.home_t1{
  /* height: 300px; */
  background: linear-gradient(to bottom right, #F73500, #F48B00);  
  /* display: flex;
  flex-direction: column;  
  align-items: center; 
  justify-content: center;  */
}




.home_t1_a1{
  color: #FFFFFF;  
  font-size: 36px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}


.home_t1_a2{
  color: #FFFFFF;
  padding: 10px;
  text-align: center;
  font-size: 18px;   
}




.home_t2{
  background-color: #FFF8F0;
  margin-top: 20px;
  padding: 20px 0;
  /* font-size: 40px; */
  /* display: flex;
  flex-direction: column;  
  align-items: left;
  justify-content: center;
  padding: 0 150px;
  margin: 50px 0 50px 0; */
}


.home_t2_t0 {  
  /* 设置文字的背景渐变 */  
  background-image: linear-gradient(to right, red, yellow);  
    
  /* 使用-webkit-前缀是为了兼容某些旧版浏览器 */  
  -webkit-background-clip: text;  
  background-clip: text;  
    
  /* 去除文字周围的背景色 */  
  color: transparent;  
    
  /* 还可以添加一些额外的样式，如文本对齐和填充 */  
  text-align: center;  
  padding: 20px;  
    
  /* 为了让渐变效果更明显，你可能需要设置字体大小和字体加粗 */  
  font-size: 48px;  
  font-weight: bold;  
}

.home_t2_t1{
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 30px;
}


.home_t2_a1{
  font-size: 18px;
  line-height: 2;
  margin-top: 20px;
}

.home_t2_a1_big{
  font-size: 20px;
  line-height: 2;
  margin-top: 20px;
}


.home_t2_l1{
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 18px;
}


.home_t2_l1_big{
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 20px;
}



/* .p{
  font-size: 30px;
} */


ul{
  list-style: disc;
}



.text-image-container {  
    display: flex;  
    align-items: center; /* 垂直居中 */  
}  

.text-container {  
    flex: 1; /* 占据剩余空间的一半 */  
    padding: 20px;  
}  

.null-container {  
    flex: 0.5;  
    padding: 20px;  
}  

.image-container {  
    flex: 1; /* 占据剩余空间的一半 */  
    text-align: center;  
}  

.image-container img {  
    max-width: 100%; /* 图片最大宽度为其容器宽度 */  
    height: auto; /* 保持图片原始比例 */  
}



.rounded-box {  
  /* ... 其他样式 ... */
  background-color: #FCE2CF;
  width: 100%;
  height: 100%;
  padding: 20px;

  /* 分别设置四个角的圆角大小 */  
  border-top-left-radius: 30px;  
  border-top-right-radius: 30px;  
  border-bottom-right-radius: 30px;  
  border-bottom-left-radius: 30px;  
}




.quk-list ul{ 
  height:219px; overflow:hidden; width:100%;
    display: flex;  
  align-items: center; /* 垂直居中 */  
  justify-content: center; /* 水平居中 */  
  }
.quk-list li{ float:left; width:145px; height:192px; padding:27px 24px 0 226px; overflow:hidden; margin-right:1px;}
.quk-list .q3{ margin-right:0;}
.quk-list .dt{ height:32px; line-height:32px; padding-bottom:8px; border-bottom:1px solid #fff; margin-bottom:12px; font-size:22px; color:#fff;}
.quk-list .dt a{ color:#fff;}
.quk-list .dd{ line-height:20px; font-size:16px; color:#fff;}






</style>